<template>
  <div id="shuttle_ticket_page">
    <inner-page-title title="Live Info"></inner-page-title>
    <div ref="google_map" style="height: calc(100vh - 240px);"></div>
    
    <!-- Fixed header map -->
    <!-- <div class="fixed-header-map">
      <div>
        sdf
      </div>
    </div> -->
    
    <div class="shuttle-info">
      <div class="container" style="margin-bottom: 10px;">

        <div style="display: flex; align-items: center; justify-content: space-between;">
          <h4>City Center Shuttle</h4>
          
          <span class="time-left">Due in 3 min</span>
        </div>

        <base-spacer space="3px"></base-spacer>

        <div class="input-group">
          <span>Direction</span>
          <el-select v-model="direction" placeholder="Direction">
            <el-option label="To the office" value="go"></el-option>
            <el-option label="Home" value="back"></el-option>
          </el-select>
        </div>

        <div class="input-group">
          <span>Stop</span>
          <el-select v-model="stop" placeholder="Direction">
            <el-option label="St. Stephen Green East" value="1"></el-option>
            <el-option label="Leeson Street Bridge" value="2"></el-option>
            <el-option label="Morehampton Road" value="3"></el-option>
            <el-option label="Opposite Donnybrook Fire Station" value="4"></el-option>
            <el-option label="Donnybrook Church" value="5"></el-option>
            <el-option label="Stillorgan Road" value="6"></el-option>
            <el-option label="Brewery Road" value="7"></el-option>
            <el-option label="Central Park" value="8"></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import initGoogleMaps from '@/utils/gmaps';

export default {
  props: {
    id: { type: String }
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    domain: process.env.VUE_APP_DOMAIN,
    bus_marker: null,
    direction: "go",
    stop: "1",
    waypoints: [
      {
        location: "St. Stephen Green East, Dublin",
        stopover: true
      },
      {
        location: "Leeson Street Bridge, Dublin",
        stopover: true
      },
      {
        location: "Morehampton Road, Dublin",
        stopover: true
      },
      {
        location: "Donnybrook Fire Station, Dublin",
        stopover: true
      },
      {
        location: "Donnybrook Church, Dublin",
        stopover: true
      },
      {
        location: "Brewery Road, Dublin",
        stopover: true
      }
    ],
    bus_locations: [
      {
        lat: 53.336300,
        lng: -6.257531
      },
      {
        lat: 53.336316,
        lng: -6.257392
      },
      {
        lat: 53.336380,
        lng: -6.257247
      },//
      {
        lat: 53.336495,
        lng: -6.257112
      },
      {
        lat: 53.336601,
        lng: -6.256994
      },
      {
        lat: 53.336748,
        lng: -6.256919
      },
      {
        lat: 53.336844,
        lng: -6.256828
      },
      {
        lat: 53.336956,
        lng: -6.256720
      },
      {
        lat: 53.337075,
        lng: -6.256586
      },
      {
        lat: 53.337203,
        lng: -6.256479
      },
      {
        lat: 53.337376,
        lng: -6.256350
      },
      {
        lat: 53.337627,
        lng: -6.256090
      },
      {
        lat: 53.337943,
        lng: -6.255929
      },
      {
        lat: 53.338209,
        lng: -6.255708
      },
      {
        lat: 53.338426,
        lng: -6.255547
      },
      {
        lat: 53.338478,
        lng: -6.255365
      },
      {
        lat: 53.338478,
        lng: -6.255365
      },
      {
        lat: 53.338478,
        lng: -6.255365
      },
      {
        lat: 53.338478,
        lng: -6.255365
      },
      {
        lat: 53.338478,
        lng: -6.255365
      },
      {
        lat: 53.338567,
        lng: -6.255215
      },
      {
        lat: 53.338478,
        lng: -6.255000
      },
      {
        lat: 53.338401,
        lng: -6.254699
      },
      {
        lat: 53.338305,
        lng: 53.338305
      },
      
      
    ]
  }),

  async mounted() {
    let google = await initGoogleMaps();
    let directionsService = new google.maps.DirectionsService;
    let directionsRenderer = new google.maps.DirectionsRenderer;

    this.map = new google.maps.Map(this.$refs.google_map, {
      zoom: 12,
      center: { lat: 53.26, lng: -6.26 },
      // mapTypeId: 'satellite',
      disableDefaultUI: true
    });

    directionsRenderer.setMap(this.map);

    directionsService.route({
      origin: "D’Olier Street, Dublin",
      destination: "Central Park, Sandyford",
      waypoints: this.waypoints,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, function(response, status) {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
      }
    });


    this.bus_marker = new google.maps.Marker({
      position: new google.maps.LatLng(53.336300,-6.257531),
      icon: {
        url: this.publicPath + 'img/shuttle-icon.svg',
        scaledSize: new google.maps.Size(40, 40),
      },
      map: this.map
    });

    await this.sleep(5000);

    for (let i = 0; i < this.bus_locations.length; i++) {
      this.bus_marker.setPosition(this.bus_locations[i]);
      await this.sleep(1000);
    }
  }
}
</script>

<style lang="scss" scoped>
  #shuttle_ticket_page {
    .shuttle-info {
      position: fixed;
      bottom: 0;
      min-height: 200px;
      z-index: 100;
      background-color: white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0px -4px 12px 2px rgba(0,0,0,0.1);
      border: 0;
      width: 100%;
    }

    .fixed-header-map {
      position: fixed;
      top: 70px;
      right: 10px;
      z-index: 99;
      display: flex;
      justify-content: center;
      padding: 0 10px;
      border: 1px solid red;
    }

    .time-left {
      border: 1px solid #EB4E3F;
      background-color: #EB4E3F;
      padding: 5px 15px;
      border-radius: 5px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      color: white;
      font-weight: 400;
    }
  }
</style>